import React from 'react';
import { UserCentricIcon } from '../../icons';

import './index.scss';
import { TelegramIcon } from '../../icons';
import { CommunicationIcon } from '../../icons';
import { PersonalDataIcon } from '../../icons';

export function AboutTatus() {
  return (
    <main id="about" className="about">
      <h1 className='aboutTitle'>
        <div className='text'>About Tatsu</div>
        <div className='btn'>KEY FEATTURES</div>
      </h1>
      <section className='abountList'>
        <div className='item'>
          <UserCentricIcon />
          <div className='desc'>
            <p>User-centric for smooth Web3 experience</p>
          </div>
        </div>
        <div className='item'>
          <TelegramIcon />
          <div className='desc'>
            <p>Seamless integration </p>
            <p>with Telegram</p>
          </div>
        </div>
        <div className='item'>
          <CommunicationIcon />
          <div className='desc'>
            <p>Bidirectional </p>
            <p>communication</p>
          </div>
        </div>
        <div className='item'>
          <PersonalDataIcon />
          <div className='desc'>
            <p>Personal data</p>
            <p>sovereignty</p>
          </div>
        </div>
      </section>
    </main>
  );
}
