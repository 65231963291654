import React from 'react';

import HeadNav from '../HeadNav';
import { ConnectIcon } from '../../icons';
import bgTopImg from '../../../static/Screen@2x.png';

import './index.scss';

export type IPrpos = {
  appType: string;
  onClick: () => void;
};
const Header = (props: IPrpos) => {
  const { appType, onClick } = props;
  const handleLink = (type: string) => {
    if (type === 'build') {
      window.open('https://docs.web3mq.com/docs/Web3MQ-SDK/JS-SDK/quickStart/');
    } else if (type === 'docs') {
      window.open('https://docs.web3mq.com/docs/Intro');
    }
  };
  return (
    <header className="headerContainer">
      <HeadNav appType={appType} visible={false} onClick={onClick} />
      <div className="headerMainBox">
        <div className="titleTag">Welcome to Tatsu</div>
        <div className="title">
          A Web3 Native Reach & Push dApp Built on Web3MQ
        </div>
        <div className="buttonGroup">
          <button className="buildButton" onClick={() => handleLink('build')}>
            Get Started →
          </button>
        </div>
        <div className="bg-top-2">
          <img src={bgTopImg} alt="" />
        </div>
      </div>
    </header>
  );
};

export default Header;
