import React from 'react';
import Layout from '../components/Layout';
import { Connected } from '../containers/Connected';
import { ComingSoon } from '../containers/ComingSoon';
import { LetsBuild } from '../containers/LetsBuild';

import WindowProvider from '../components/WinowProvider';
import { AboutTatus } from '../containers/AboutTatus';
import SEO from '../components/Seo';

import './index.scss';

const Web3MQ = () => {

  return (
    <WindowProvider>
      <SEO>
        <Layout>
          <Connected />
          <ComingSoon />
          <LetsBuild />
          <AboutTatus />
        </Layout>
      </SEO>
    </WindowProvider>
  );
};

export default Web3MQ;
