import React, { ReactNode, useEffect, useState } from 'react';
import boardImage1 from '../../../static/connect_forever@2x.png';
import boardImage2 from '../../../static/Frame_47646@2x.png';
import connectEverywhereImg from '../../../static/03@2x.png';
import connectAllImg from '../../../static/04@2x.png';

import './index.scss';
import { Card } from '../../components/Card';

interface ISwiper {
  children: ReactNode;
  activeKey: number;
}
function Swiper(props: ISwiper) {
  const { children, activeKey } = props;

  // const count = React.Children.count(children)
  return (
    <div
      className="swiper"
      style={{ transform: `translateY(-${activeKey * 419}px)` }}
    >
      {children}
    </div>
  );
}

export function Connected() {
  const [featIndex, setFeatIndex] = useState<number>(0);
 
  return (
    <>
      <main id="connected" className="connected">
        <h2 className="headerTitle-mobile">
          Connected <span className="blue">Forever</span>
        </h2>
        <section className="column-2">
          <Swiper activeKey={featIndex}>
            <Card img={boardImage1} visible={featIndex === 0}/>
            {/* show in mobile client */}
            <div className="item active item-mobile">
              <h2 className="itemTitle">Asynchronous communication</h2>
              <div className="desc">
                Telegram lacked "asynchronous communication." Now, it has it.
                With Tatsu, remain connected to the real-time status of the
                people you care about, forever.
              </div>
            </div>
            <Card img={boardImage2} visible={featIndex === 1}/>
            {/* show in mobile client */}
            <div className="item active item-mobile">
              <h2 className="itemTitle">Network layer connections</h2>
              <div className="desc">
                Your social connections will not only reside at the application
                level, but also at a deeper communication network layer
                (Web3MQ). Let the network layer help you remember, instead of
                the application.
              </div>
            </div>
          </Swiper>
        </section>
        <section className="column-1">
          <h2 className="headerTitle">
            Connected <span className="blue">Forever</span>
          </h2>
          <div className="cate">
            <div
              className={`item ${featIndex === 0 ? 'active' : ''}`}
              onClick={() => setFeatIndex(0)}
            >
              <h2 className="itemTitle">Asynchronous communication</h2>
              <div>
                Telegram lacked "asynchronous communication." Now, it has it.
                With Tatsu, remain connected to the real-time status of the
                people you care about, forever.
              </div>
            </div>
            <div
              className={`item ${featIndex === 1 ? 'active' : ''}`}
              onClick={() => setFeatIndex(1)}
            >
              <h2 className="itemTitle">Network layer connections</h2>
              <div>
                Your social connections will not only reside at the application
                level, but also at a deeper communication network layer
                (Web3MQ). Let the network layer help you remember, instead of
                the application.
              </div>
            </div>
          </div>
        </section>
      </main>
      <main className="connectEverywhere">
        <section className="column-1">
          <h2 className="headerTitle">
            Connected <span className="blue">Everywhere</span>
          </h2>
          <div className="descConent">
            Telegram is merely the beginning. In all places connected to Web3MQ
            (games, social dapps, wallets), you can receive updates from your
            social network.
          </div>
        </section>
        <section className="column-2">
          <img src={connectEverywhereImg} alt="" />
        </section>
      </main>
      <main className="connectAll">
        <section className="column-1">
          <img src={connectAllImg} alt="" />
        </section>
        <section className="column-2">
          <h2 className="headerTitle">
            Connected <span className="blue">For All</span>
          </h2>
          <div className="descConent">
            Tatsu's communication network guarantees data sovereignty for
            everyone at the physical level. In this decentralized network, no
            party is "more equal" than another (including us).
          </div>
        </section>
      </main>
    </>
  );
}
