import React, { ReactNode, useEffect, useState } from 'react';
import Header from '../Header';
import SideNavBar from '../SideNavBar';
import Footer from '../Footer';

import './index.scss';
interface IProps {
  children: ReactNode
}

const Layout = ({ children }: IProps) => {
  const [appType, setAppType] = useState<string>(window.innerWidth <= 768 ? 'h5' : 'pc');
  const [isShowNav, setIsShowNav] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 768 ? 'h5' : 'pc');
      if (window.innerWidth > 768) {
        setIsShowNav(false)
      }
    });
  }, []);

  return (
    <div className='layoutContainer'>
      <Header appType={appType} onClick={() => setIsShowNav(true)} />
      <SideNavBar visible={isShowNav} onClose={() => setIsShowNav(false)} />
        {children}
      <Footer />
    </div>
  );
};

export default Layout;
