import React from 'react';
import poweredByImg from '../../../static/Powered_by@2x.png';
import {
  TatsuIcon,
} from '../../icons';

import './index.scss';



const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerTop">
        <TatsuIcon />
        <div className="footerLinks">
          <a
            className="footerLink"
            href=""
            target="_blank"
          >
            Twitter
          </a>
          <a
            className="footerLink"
            href=""
            target="_blank"
          >
            Email
          </a>
          <a
            className="footerLink"
            href=""
            target="_blank"
          >
            Social Kit
          </a>
        </div>
      </div>
      <div className="footerBottom">
       <div className='poweredBy'>
          <img src={poweredByImg} alt="" />
          <div className='desc'>
            Web3MQ's key features: trustless, seamless, permissionless composability, and interoperability
          </div>
       </div>
        <div className='rightsStatment'>
          <div className="footerRights">2023 Tatsu All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
