import React from 'react';

import './index.scss';

export function LetsBuild() {
  return (
    <main id="letsBuild" className="letsBuild">
      <section>
        <h1 className='title'>
          <div>Now, Let’s Build</div>
          <div>Your Telegram Circle of Friends</div>
        </h1>
        <div className='desc'>
          Say goodbye to tedious group management. Access high-qualit,y first-hand
          information without sifting through group chats.
        </div>
      </section>
      <section>
        <button className="startBtn">Get started with Tatsu today →</button>
      </section>
    </main>
  );
}
