import React from 'react';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

type Iprops = {
  title?: string;
  description?: string;
  pathname?: string;
  children: any;
};
const SEO = (props: Iprops) => {
  const {
    title = 'Tatsu',
    description = 'Tatsu',
    pathname,
    children,
  } = props;
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle || '',
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };
  console.log(seo, 'seo');

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:site_name" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="og:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="og:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <link
        rel="icon"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>"
      />
      {children}
    </>
  );
};
export default SEO;
