import React from 'react';

export const ConnectIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.651 2.03598C10.9537 1.97934 11.2652 1.98967 11.5635 2.06629C11.8641 2.14349 12.144 2.28608 12.3831 2.48389C12.6222 2.6817 12.8148 2.92985 12.947 3.21064C13.0792 3.49144 13.1478 3.79794 13.1479 4.1083V4.71044C14.0224 4.94503 14.6663 5.40991 14.6663 6.35851V11.897C14.6663 13.0299 13.748 13.9483 12.6151 13.9483H3.38429C2.2514 13.9483 1.33301 13.0299 1.33301 11.897V6.82005V6.35851V5.40678C1.33278 4.91586 1.50382 4.44004 1.81666 4.0617C2.12959 3.68324 2.56478 3.42585 3.04718 3.33392C3.05139 3.33312 3.0556 3.33236 3.05982 3.33164L10.651 2.03598ZM11.1663 10C10.9686 10 10.7752 9.94135 10.6108 9.83147C10.4463 9.72159 10.3181 9.56541 10.2425 9.38268C10.1668 9.19996 10.147 8.99889 10.1856 8.80491C10.2241 8.61093 10.3194 8.43275 10.4592 8.29289C10.5991 8.15304 10.7773 8.0578 10.9713 8.01922C11.1652 7.98063 11.3663 8.00043 11.549 8.07612C11.7318 8.15181 11.8879 8.27998 11.9978 8.44443C12.1077 8.60888 12.1663 8.80222 12.1663 9C12.1663 9.26522 12.061 9.51957 11.8734 9.70711C11.6859 9.89464 11.4316 10 11.1663 10ZM11.8145 4.10872V4.64056L5.26879 4.30723L10.8811 3.34933C10.8853 3.34861 10.8895 3.34785 10.8937 3.34705C11.0058 3.32568 11.1213 3.32932 11.2318 3.35771C11.3424 3.3861 11.4453 3.43855 11.5333 3.5113C11.6212 3.58405 11.692 3.67532 11.7407 3.77859C11.7893 3.88179 11.8145 3.99465 11.8145 4.10872Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
