import React from 'react';

export const TelegramIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="84"
        height="84"
        viewBox="0 0 84 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="83" height="83" rx="41.5" fill="#F4FAFF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.627 55.9066L37.1965 47.301L52.8206 33.2231C53.5122 32.5926 52.6783 32.2874 51.7626 32.8366L32.4769 45.0227L24.136 42.3779C22.3457 41.8692 22.3254 40.6283 24.5428 39.7331L57.0317 27.2016C58.5168 26.5301 59.9409 27.5676 59.3713 29.8461L53.8377 55.9066C53.4513 57.7577 52.3323 58.2054 50.7862 57.351L42.3638 51.1257L38.3155 55.0521C37.8477 55.5199 37.461 55.9066 36.627 55.9066Z"
          fill="#58A5E4"
        />
        <rect
          x="0.5"
          y="0.5"
          width="83"
          height="83"
          rx="41.5"
          stroke="#E8F4FF"
        />
      </svg>
    </div>
  );
};
