import React from 'react';
import cx from 'classnames';

import { CloseIcon, ConnectIcon, MenuIcon, TatsuIcon } from '../../icons';

import './index.scss';

export type IProps = {
  appType: string;
  className?: string;
  style?: React.CSSProperties;
  visible: boolean;
  onClick: () => void;
};
export const leftNavigation = [
  { name: 'Connected', href: '/#connected', current: true },
  { name: 'About Tatsu', href: '/#about', current: true },
  { name: 'Contact Us', href: '/#Contact', current: true },
];

export const bottomNavigation = [
  { name: 'Docs', href: 'https://docs.web3mq.com/docs/Intro', current: false },
  { name: 'Join us', href: 'https://www.notion.so/s3labs/Working-at-S3-Labs-1bb5dd8a93c641d8aa2bdf3938ccee7a?pvs=4', current: false },
  { name: 'Community', href: '/#community', current: true },
];

const HeadNav = React.memo((props: IProps) => {
  const { appType, className, style, visible, onClick } = props;
  const handleLink = (type: string) => {
    if (type === 'build') {
      window.open('https://docs.web3mq.com/docs/Web3MQ-SDK/JS-SDK/quickStart/')
    } else if (type === 'github') {
      window.open('https://github.com/Generative-Labs')
    }
  }
  return (
    <nav className={cx('headerNavContainer', className)} style={style}>
      <div className='leftNav'>
        <TatsuIcon className='tatsuIcon' />
      </div>
      <div className="centerNav">
        {appType === 'pc' && (
          <div className='navs'>
            {leftNavigation.map(nav => (
              <a className='navLink' href={nav.href} key={nav.name} target={!nav.current ? '_blank' : undefined}>{nav.name}</a>
            ))}
          </div>
        )}
      </div>
      <div className='rightNav'>
        {appType === 'pc' ? (
          <>
            <button className='connectButton' onClick={() => handleLink('build')}><ConnectIcon />Connect Wallet</button>
          </>
        ) : (
          <>
            {visible ? <CloseIcon onClick={onClick} /> : <MenuIcon onClick={onClick} />}
          </>
        )}
      </div>
    </nav>
  );
});

export default HeadNav;