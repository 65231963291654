import React, {  } from 'react';
import { ContentWriterIcon, SubscriberIcon } from '../../icons';
import rightImg1 from '../../../static/05@2x.png';
import leftImg1 from '../../../static/Frame_47685@2x.png';

import './index.scss';

export function ComingSoon() {
  return (
    <main id="comming" className="comming">
      <section className='contentWriter'>
        <div className='comingTitle'>
          <span>COMING SOON</span>
        </div>
        <div className='subTitle'>
          <span>An economic system </span><span>that benefits </span><span>everyone</span>
        </div>
        <div className="comingContent">
          <div className="desc">Choose Tatsu and embrace the future economic system of information distribution.</div>
        </div>
        <div className="mainContent">
          <div className="left">
            <div className='writerRow'>
              <ContentWriterIcon className="row"/>
              <h1 className='mcTitle row'>Content Writer</h1>
            </div>
            <div className='mcDesc'>
              Every content creator (KOLs, KOCs, regular users) can set a price for their own information.
            </div>
          </div>
          <div className="right row">
            <img src={rightImg1} alt="" />
          </div>
        </div>
      </section>
      <section className='subscribers mainContent'>
        <div className="left">
          <img src={leftImg1} alt="" />
        </div>
        <div className="right">
          <div className="subRow">
            <SubscriberIcon className="row subIcon"/>
            <h1 className='mcTitle row'>Subscribers</h1>
          </div>
          <div className='mcDesc'>
            All subscribers have the freedom to choose whether to receive information and the method of delivery.
          </div>
        </div>
      </section>
    </main>
  );
};
