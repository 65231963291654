import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import './index.scss'
interface ICard {
  img: any
  visible: boolean
}
export function Card(props: ICard) {
  const { img, visible } = props
  const nodeRef = useRef(null)
  const [appType, setAppType] = useState<string>(window.innerWidth <= 768 ? 'h5' : 'pc');
  const show = useMemo(() => {
    if (appType === 'pc') {
      return visible
    }
    return true;
  }, [appType, visible])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppType(window.innerWidth <= 900 ? 'h5' : 'pc');
    });
  }, []);
  return (
    <section className={`m-card ${show ? '' : 'hidden'}`} ref={nodeRef}>
      <img src={img} alt="" />
    </section>
  )
}